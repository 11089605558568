.wrapper {
    margin-bottom: 20px;
    transition: all .2s ease-in-out;
}

.wrapper:hover {
    transform: scale(1.01);
}

.link-style {
    text-decoration: none;
    color: inherit;
}

.link-style:hover {
    text-decoration: none !important;
    color: inherit;
}

.image-card {
    width: 100%;
    height: 40vh;
    background-color: whitesmoke;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.text-wrapper {
    margin-top: 10px;
}

.text-wrapper:hover {
    color: black;
}

.card-name {
    opacity: 0.5 !important;
}

.card-price {
    font-size: 13px !important;
    font-weight: bold !important;
}

.add-to-cart:hover {
    background-color: #6c757d;
    color: white;
}

.image-card .add-to-cart {
    opacity: 0;
}

.image-card:hover .add-to-cart {
    opacity: 1;
}
