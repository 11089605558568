@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500&display=swap");

body {
    margin: 0;
    font-family: "Montserrat", sans-serif !important;
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.btn:focus {
    outline: none !important;
    box-shadow: none !important;
}

ul li {
    list-style: none;
    padding: 0;
    margin: 10px;
}

.grid-wrap {
    position: relative;
    padding: 0;
    margin: 10px;
}

.grid-wrap ul {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, auto));
    grid-auto-flow: dense;
    gap: 2px;
    justify-content: center;
    margin: 0 auto;
    padding: 0;
}

.grid-wrap ul li {
    position: relative;
}

.grid-wrap ul li:nth-child(1) {
    grid-row: span 2;
    grid-column: span 4;
}

.grid-wrap ul li:nth-child(2) {
    grid-row: span 2;
    grid-column: span 4;
}

.grid-wrap ul li:nth-child(3) {
    grid-row: span 2;
    grid-column: span 5;
}

.grid-wrap ul li:nth-child(4) {
    grid-row: span 2;
    grid-column: span 6;
}

.grid-wrap ul li:nth-child(5) {
    grid-row: span 3;
    grid-column: span 4;
}

.grid-wrap ul li:nth-child(6) {
    grid-row: span 5;
    grid-column: span 4;
}

.grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: minmax(180px, auto);
    gap: 10px;
    grid-template-areas:
        "image1 image2 image3"
        "image1 image2 image3"
        "image4 image2 image3"
        "image4 image2 image6"
        "image4 image5 image6"
        "image4 image5 image6";
}

@media (max-width: 480px) {
    .grid-container {
        grid-template-columns: 1fr;
        grid-auto-rows: 500px;
        grid-template-areas:
        "image1"
        "image2"
        "image3"
        "image4"
        "image5"
        "image6";
    }
}

@media (min-width: 481px) and (max-width: 768px) {
    .grid-container {
        grid-template-columns: 1fr 1fr;
        grid-auto-rows: minmax(150px, auto);
        grid-template-areas:
        "image1 image2"
        "image1 image2"
        "image3 image2"
        "image3 image2"
        "image4 image5"
        "image4 image5"
        "image4 image6"
        "image4 image6";
    }
}

.grid-item-1 {
    grid-area: image1;
}

.grid-item-2 {
    grid-area: image2;
}

.grid-item-3 {
    grid-area: image3;
}

.grid-item-4 {
    grid-area: image4;
}

.grid-item-5 {
    grid-area: image5;
}

.grid-item-6 {
    grid-area: image6;
}

.image {
    width: 100%;
    height: auto;
    background-color: whitesmoke;
    /* background-image: url(${image}); */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.card-content {
    visibility: hidden;
}

.card-content:hover {
    visibility: visible;
}

.list-group-item.active {
    background-color: #212529 !important;
    border-color: #212529 !important;
}
