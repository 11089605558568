.header {
    height: 30vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: whitesmoke;
    font-size: 3rem;
    font-weight: 400;
}

.category {
    font-weight: 400;
    cursor: pointer;
    padding-bottom: 5px;
    border-bottom: 1px solid whitesmoke;
}

.empty {
    height: 30vh;
    display: flex;
    justify-content: center;
}