.header {
    height: 30vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: whitesmoke;
    font-size: 3rem;
    font-weight: 400;
}

.image {
    width: 100%;
    height: auto;
    background-color: whitesmoke;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    cursor: pointer;
}

.grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: minmax(180px, auto);
    gap: 10px;
    grid-template-areas:
        "image1 image2 image3"
        "image1 image2 image3"
        "image4 image2 image3"
        "image4 image2 image6"
        "image4 image5 image6"
        "image4 image5 image6";
}

@media (max-width: 480px) {
    .grid-container {
        grid-template-columns: 1fr;
        grid-auto-rows: 500px;
        grid-template-areas:
        "image1"
        "image2"
        "image3"
        "image4"
        "image5"
        "image6";
    }
}

@media (min-width: 481px) and (max-width: 768px) {
    .grid-container {
        grid-template-columns: 1fr 1fr;
        grid-auto-rows: minmax(150px, auto);
        grid-template-areas:
        "image1 image2"
        "image1 image2"
        "image3 image2"
        "image3 image2"
        "image4 image5"
        "image4 image5"
        "image4 image6"
        "image4 image6";
    }
}

.grid-item-1 {
    grid-area: image1;
}

.grid-item-2 {
    grid-area: image2;
}

.grid-item-3 {
    grid-area: image3;
}

.grid-item-4 {
    grid-area: image4;
}

.grid-item-5 {
    grid-area: image5;
}

.grid-item-6 {
    grid-area: image6;
}

.lightbox {
    position: fixed;
    z-index: 1000;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
}

.lightbox img {
    width: auto;
    max-height: 90%;
}